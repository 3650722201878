<template>
    <v-btn width="100%" max-width="480" class="primary--text rounded-0 mx-auto elevation-0" color="white" @click="modelDialog = true">
        <span class="text-button font-weight-bold">Часто задаваемые вопросы</span>
        <v-dialog v-model="modelDialog" max-width="700" scrollable>
            <v-card>
                <v-card-title>
                    <span>ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</span>
                    <v-spacer />
                    <v-btn fab small color="primary" @click="modelDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <v-expansion-panels class="mt-4">
                        <v-expansion-panel>
                            <v-expansion-panel-header class="pt-4"><b>Расписание сеансов</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <ul class="mb-4">
                                    <li>09:00 - 10:00</li>
                                    <li>10:30 - 11:30</li>
                                    <li>12:00 - 13:00</li>
                                    <li>13:30 - 14:30</li>
                                    <li>15:00 - 16:00</li>
                                    <li>16:30 - 17:30</li>
                                    <li>18:00 - 19:00</li>
                                    <li>19:30 - 20:30</li>
                                    <li>21:00 - 22:00</li>
                                    <li>22:30 - 23:30</li>
                                </ul>

                                <!-- ------------------------------- -->
                                <p>Обращаем Ваше внимание, что расписание сеансов для массового катания может быть изменено решением администрации Катка.</p>
                                <p>
                                    Актуальную информацию по расписанию сеансов, доступных для катания, можно посмотреть на сайте в разделе РАСПИСАНИЕ на ближайшие 7 (семь) дней.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- <p>
                                Обращаем Ваше внимание, что расписание сеансов для массового катания может быть изменено решением администрации Катка. <br />
                                Актуальную информацию по расписанию сеансов, доступных для катания, можно посмотреть
                                <a href="#" @click.prevent="ScrollTo('#section-schedule')">ТУТ</a>
                            </p> -->
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Техподдержка</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Информационный центр по работе Катка и Ярмарки на Красной площади работает каждый день с
                                    10:00 до 22:00 по телефону +7 (495) 620-32-27, электронная почта <a href="mailto:info.katok@gum.ru">info.katok@gum.ru</a>
                                    1 января информационный центр начинает работу с 12:00.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header><b>Продажа билетов</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Чтобы исключить очереди в кассы и минимизировать прямые контакты, билеты продаются онлайн
                                    или через кассовые терминалы, которые расположены в кассах при входе на ГУМ-Каток со стороны
                                    улиц Никольская и Ильинка.
                                </p>
                                <p>
                                    Продажа онлайн билетов производится только на сайте GUM-Katok.ru. Продажа онлайн билетов
                                    заканчивается за 1,5 часа до начала сеанса.
                                </p>
                                <p>
                                    За один сеанс ледовая площадка вмещает не более 450 чел.
                                    Во время проведения мастер-классов количество билетов ограничено до 300, т.к. часть ледового
                                    поля перекрывается для данного мероприятия.
                                </p>
                                <p>
                                    Глубина продаж в период с 5 декабря по 24 декабря и с 9 января по 9 марта составляет не более
                                    6-ти дней, следующих за днем покупки, и начинается после 02:00.
                                </p>
                                <p>
                                    Глубина продаж в период Новогодних праздников с 25 декабря по 8 января составляет 3 дня,
                                    включая дату покупки.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Бесплатные сеансы</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Бесплатные сеансы проводятся по будним дням, исключая дни Новогодних каникул (с 25 декабря
                                    2024г по 08 января 2025г), в период с 09:00 до 14:30 (включительно), за исключением сеансов,
                                    закрытых для свободного катания. Информацию по расписанию сеансов, доступных для катания,
                                    можно посмотреть на сайте в разделе РАСПИСАНИЕ
                                </p>
                                <p>
                                    Получить билет на бесплатный сеанс катания можно в день посещения катка только в кассе, не
                                    раннее, чем за 1 час до начала сеанса.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Билет на Новогодний сеанс</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Билет на Новогодний сеанс продается только в кассах катка. Билеты поступают в продажу
                                    ориентировочно с 15 декабря. Точную дату продажи можно уточнить по электронной почте <a href="mailto: info.katok@gum.ru">info.katok@gum.ru</a> или по телефону +7 (495) 620 32 27. Услуга предварительного бронирования не
                                    предоставляется.
                                </p>
                                <p>
                                    В стоимость Билета входит 4 часа катания (с 22:00 31 декабря до 02:00 1 января) с техническим
                                    перерывом, анимационные и развлекательные программы и выход на Красную площадь не
                                    предусмотрены.
                                </p>
                                <p>
                                    Вход на Новогодний сеанс осуществляется с 21:30 до 23:30. При покупке Билета выдается
                                    специальный вкладыш со схемой движения на Каток. Поэтому, претензии, что посетителя не
                                    пустили на Каток, потому что он двигался не по указанному маршруту, не принимаются.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Получение льготных билетов</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Получить билет на бесплатный сеанс катания можно в день посещения катка только в кассе, не
                                    раннее, чем за 1 час до начала сеанса.
                                </p>
                                <p>
                                    Обращаем ваше внимание, что билет на бесплатный сеанс не включает в себя бесплатное
                                    использование проката спортивного инвентаря и дополнительных услуг (аренда игрушки
                                    «Помощник фигуриста» и заточка коньков).
                                </p>
                                <p>Бесплатные сеансы предусмотрены с понедельника по пятницу в период с 09:00 по 14:30.</p>
                                <p>
                                    Купить билет пенсионера, льготный билет для участников СВО и членов их семей или билет
                                    сопровождения можно только в кассах катка с глубиной не более 6-ти дней, следующих за днем
                                    обращения в период с 5 декабря по 24 декабря и с 9 января по 9 марта. В Новогодние праздники с
                                    25 декабря по 8 января глубина продаж сокращается до 3-х дней, включая дату покупки.
                                </p>
                                <p>
                                    Для покупки билета пенсионеру, участнику СВО или членам его семьи, необходимо предъявить
                                    документ, подтверждающий льготу, или его копию.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Система лояльности ГУМ и BOSCO</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Для владельцев дисконтных карт ГУМа предусмотрены скидки при покупке билетов КАТАНИЯ,
                                    билета на Новогодний сеанс и услуги ВИП-комнаты:
                                </p>
                                <ul class="mb-4">
                                    <li>для бордовой карты ГУМа- скидку 5%,</li>
                                    <li>для серебряной карты ГУМа– скидку 10%,</li>
                                    <li>для золотой карты ГУМа – скидку 15%.</li>
                                </ul>
                                <p>
                                    Для владельцев дисконтных карт BOSCO предусмотрены скидки при покупке билетов КАТАНИЯ,
                                    билета на Новогодний сеанс и услуги ВИП-комнаты:
                                </p>
                                <ul class="mb-4">
                                    <li>для серебряной карты Bosco - скидку 5%,</li>
                                    <li>для золотой карты Bosco– скидку 10%,</li>
                                    <li>для шоколадной карты Bosco – скидку 15%,</li>
                                    <li>для карты Приве Bosco – скидку 20%.</li>
                                </ul>
                                <p>
                                    Скидки предоставляются при покупке билетов в кассах ГУМ-катка и on-line продажах через сайт
                                    ГУМ-каток. <br />
                                    Скидки при покупке через уличный кассовый терминал не предоставляются. <br />
                                    Бонусы на карту Bosco при покупке БИЛЕТА НА КАТАНИЕ не начисляются.<br />
                                    Скидки не распространяются на прокат спортивного инвентаря, билет сопровождения. <br />
                                    Скидки по дисконтным картам не суммируются с другими скидками.
                                </p>

                                <p>
                                    В случае не предоставления скидки при применении дисконтной карты, необходимо обратиться в call-центр по телефону
                                    <a href="tel: +7 (495) 660 05 50"> +7 (495) 660 05 50 </a> для уточнения причины
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Продажа пригласительных билетов</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    В кассах катка можно приобрести пригласительные билеты, которые являются документом,
                                    удостоверяющий принадлежащее Клиенту право требования обмена на Билет. Билеты продаются в
                                    количествах от 10 до 100 шт. Скидки по дисконтным картам ГУМа и Bosco не предоставляются.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Обмен пригласительного билета</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Срок действия пригласительных билетов ограничивается периодом с 05 декабря 2024 года по 02
                                    марта 2025 года.
                                </p>
                                <p>
                                    Обменять пригласительный билет можно в кассе катка на сеанс при условии, что он открыт для
                                    свободного катания и наличия свободных мест. <span style="color:red">До какого числа проводится обмен.</span>
                                </p>
                                <p>Услуга по бронированию билетов для обмена пригласительного билета не предоставляется.</p>
                                <p>Расписание сеансов, открытых для катания, можно посмотреть на сайте в разделе РАСПИСАНИЕ.</p>
                                <p>
                                    Глубина обмена пригласительного билета на билет Катка составляет не более 6 дней, следующих
                                    за днем обращения в кассу в период с 5 декабря по 24 декабря и с 9 января по 2 марта. В
                                    Новогодние праздники с 25 декабря по 8 января глубина обмена сокращается до 3-х дней, включая
                                    дату обращения.
                                </p>
                                <p>
                                    Обращаем ваше внимание, что пригласительный билет не включает в себя бесплатное
                                    использование проката спортивного инвентаря и дополнительных услуг (аренда игрушки
                                    «Помощник фигуриста» и заточка коньков). Срок действия ПРИГЛАСИТЕЛЬНОГО БИЛЕТА указан на
                                    отрывном купоне билета. Удаление какой-либо части Билета может привести к недействительности
                                    Билета. ПРИГЛАСИТЕЛЬНЫЙ БИЛЕТ, предъявленный на цифровом носителе (фотография или скан)
                                    является недействительным.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Проход на каток</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Проход на каток возможен через турникеты, расположенные со стороны улиц Никольская и
                                    Ильинка.
                                </p>
                                <p>Запуск посетителей на сеанс осуществляется за 15 мин до начала сеанса.</p>
                                <p>
                                    Для того, чтобы проход через турникеты осуществлялся без задержек, рекомендуем распечатать
                                    электронные билеты.
                                </p>
                                <p>
                                    Обращаем внимание на то, что наличие кассового чека или банковской выписки без предъявления
                                    самих билетов на Каток не дает право прохода на территорию катка и получению услуги.
                                </p>
                                <p>
                                    В случае закрытия Красной площади для посетителей, проход на ГУМ-Каток осуществляется через
                                    2-ой этаж центрального входа ГУМа или, если ГУМ закрыт для посетителей, то через улицу
                                    Никольская с предъявлением билетов на посту охраны.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Меры безопасности</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    При входе на территорию катка, при проходе через турникеты необходимо соблюдать социальную
                                    дистанцию.
                                </p>
                                <p>
                                    В павильоне проката мы регулярно обрабатываем специальными средствами все поверхности,
                                    коньки проката, после каждого гостя, проходят антибактериальную обработку на сушилке. Для
                                    безопасности, посетителям предлагаются одноразовые бахилы.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Билеты</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Если вы приезжаете на каток со своими коньками, то вам нужно приобрести билет на КАТАНИЕ.
                                    Нужен прокат коньков, покупаете билет КАТАНИЕ С ПРОКАТОМ.
                                </p>

                                <p>
                                    Билет ДЕТСКИЙ действует для детей с 7 лет (включительно) до 12 лет (включительно). Так же
                                    имеет две категории: КАТАНИЕ ДЕТСКИЙ и КАТАНИЕ С ПРОКАТОМ ДЕТСКИЙ.
                                </p>

                                <p>
                                    Дети до 6 лет (включительно) проходят на каток бесплатно в сопровождении взрослого при
                                    покупке билета КАТАНИЕ КОМБО или КАТАНИЕ КОМБО С ПРОКАТОМ. <br />
                                    Обращаем ваше внимание на то, что дети до 6 лет (включительно) на лед выходят только в
                                    сопровождении взрослого. Выход на лед без коньков запрещен.
                                </p>

                                <p>
                                    Купить билет на ПРОКАТ коньков возможно лишь в кассе катка или кассе проката при
                                    предъявлении билета на КАТАНИЕ. При получении услуги проката необходимо оставлять ЗАЛОГ за
                                    коньки 2 000 рублей наличными или документы (водительское удостоверение или свидетельство о
                                    регистрации ТС; удостоверение военнослужащего; пенсионное удостоверение).
                                </p>

                                <p>Билет ПРОКАТ ДЕТСКИЙ действителен для детей от 7 лет (включительно) до 12 лет (включительно).</p>

                                <p>Внимание: Паспорт в залог не принимается</p>
                                <p>
                                    Билет ПРОКАТ ДЕТСКИЙ действителен для детей от 7 лет (включительно) до 12 лет
                                    (включительно).
                                </p>
                                <p>
                                    Прокат коньков для детей до 6 лет (включительно) бесплатный при предъявлении билета КАТАНИЕ
                                    КОМБО или КАТАНИЕ КОМБО С ПРОКАТОМ.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Участие в мастер-классах</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Для участия в мастер-классе по хоккею или фигурному катанию необходимо зарегистрироваться на
                                    сайте, кликнув на баннер.
                                </p>
                                <p>
                                    Участие бесплатное, количество мест ограничено: 24 человек на сеанс мастер-класса по хоккею и
                                    30 человек на сеанс мастер-класса по фигурному катанию.
                                </p>
                                <p>
                                    Обращаем ваше внимание, что мастер-класс по хоккею организуется для детей возрастной
                                    категории 7-12 лет. Мастер-классы делятся на категории: все желающие и начинающие
                                    спортсмены – воспитанники спортивных школ.
                                </p>
                                <p>
                                    Мастер-классы по фигурному катанию организовываются для всех возрастных категорий,
                                    единственное требование – умение кататься на коньках.
                                </p>
                                <p>Регистрация начинается за 6 дней до предполагаемой даты проведения, включая дату проведения.</p>
                                <p>Регистрация открывается в 10:00.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>ВИП-обслуживание</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Для тех, кто предпочитает индивидуальный сервис, работает ВИП-комната. <br />
                                    Максимальная вместимость ВИП-комнаты составляет 8 человек. <br />
                                    Время аренды комнаты разделяется на сеансы, каждый из которых длится 1 час. <br />
                                    Билет на аренду ВИП-комнаты действителен на вход/выход в течение времени сеанса, указанного
                                    на билете, а также времени на переодевание, за 15 минут до начала сеанса указанного в билет и
                                    15 минут после окончания сеанса. <br />
                                    Услуги, предоставляемые при аренде ВИП-комнаты:
                                </p>
                                <ul class="mb-4">
                                    <li>катание на ледовой площадке;</li>
                                    <li>прокат коньков без залога;</li>
                                    <li>прокат дополнительного спортивного инвентаря (защита, шлем);</li>
                                    <li>прокат Помощника фигуриста (не более 2-х единиц);</li>
                                    <li>отдельный туалет.</li>
                                </ul>
                                <p>
                                    При ВИП-комнате постоянно находится хостесс. <br />
                                    В стоимость аренды ВИП-комнаты не входит стоимость дополнительных услуг, а также
                                    обслуживание в зонах кафе и ярмарки, и оплачивается посетителями отдельно в кассах катка и
                                    кафе и ярмарки. <br />
                                    Скидки по картам лояльности ГУМа и Bosco на аренду ВИП-комнаты предоставляются. Скидки по
                                    картам лояльности ГУМа и Bosco не суммируются.
                                </p>
                                <p>
                                    Бронирование услуги ВИП-комнаты осуществляется по электронной почте
                                    <a href="mailto: info.katok@gum.ru">info.katok@gum.ru</a> или по телефону
                                    <a href="tel: +7 (495) 620 32 27">+7 (495) 620 32 27</a>.
                                </p>
                                <p>Максимальное период бронирования составляет 7 дней, включая дату оказания услуги.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Коньки</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Для посетителей в прокат предлагаются фигурные и хоккейные коньки.<br />
                                    Фигурные коньки представлены моделями отечественного производства брендом RoyalSkate, разработанными специально для ГУМ-Катка,
                                    с размерным рядом от 26 по 43.<br />
                                    Хоккейный ряд представлен популярными моделями брендов Bauer, CCM, Graf с размерным рядом от 25 до 48.<br />
                                    При получении услуги проката необходимо оставлять ЗАЛОГ за коньки 2 000 рублей наличными
                                    или документы (водительское удостоверение или свидетельство о регистрации ТС; удостоверение
                                    военнослужащего; пенсионное удостоверение)
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->

                            <v-expansion-panel-header><b>Гардероб</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Гардероб располагается в помещении проката. К удобствам посетителей предоставлены лавки с вешалками, бесплатная камера
                                    хранения на 550 ячеек.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>

                            <!-- ------------------------------- -->

                            <v-expansion-panel-header><b>Дополнительные услуги</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    По желанию, посетители могут воспользоваться дополнительными услугами: <br />
                                    - ЗАТОЧКА КОНЬКОВ.
                                </p>

                                <p>
                                    Находится в помещении Проката, оплата производится в кассе Проката. Заточка производится на
                                    полуавтоматических станках, настроенных на стандартные параметры. Инвентарь,
                                    предназначенный для профессионального катания (фигурные и хоккейные коньки) в работу не
                                    принимается. <br />
                                    - аренда ИГРУШКА ПОМОЩНИК ФИГУРИСТА.
                                </p>

                                <p>
                                    Помощник фигуриста оснащен полозьями и обеспечивает надежную точку опоры для детей,
                                    которые только встают на коньки. Игрушка имеет вид забавных зверей: пингвин, медвежонок,
                                    панда. С помощью такого тренажера начинающие фигуристы могут избежать нежелательных травм
                                    и падений. Оплатить аренду Помощника фигуриста можно в кассе Проката.<br />
                                    - прокат ЗАЩИТЫ и ШЛЕМ (детский размер) выдается бесплатно по желанию посетителя при прокате коньков.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Медпункт</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>На территории находится медпункт, который работает в общем режиме катка.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Кафе</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    На территории катка расположено небольшое кафе, где во время сеанса можно выпить горячего
                                    чая или кофе, угоститься пирожными или синамонами.
                                </p>
                                <p>
                                    Посещение кафе возможно в течении времени сеанса, указанного на билете, а также времени на переодевание, за 15 минут до начала
                                    сеанса, указанного в билет и 15 минут после окончания сеанса.
                                </p>
                                <p>
                                    В случае превышения времени нахождения в кафе, посетитель обязан произвести доплату в
                                    соответствии с действующими тарифами.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!-- ------------------------------- -->
                            <v-expansion-panel-header><b>Возврат билетов, приобретенных в кассах катка</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Для возврата неиспользованного Билета, приобретенного в кассе ГУМ-Катка, нужно обратиться в
                                    Кассу, заполнить заявление о возврате, приложив к нему билет и кассовый чек, уведомление из
                                    банка о списании денежных средств (банковский слип либо справка по операции) – в случае
                                    оплаты безналичными средствами, при этом время до начала сеанса должно быть не менее 30
                                    минут.
                                </p> 
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <!--------------------------------- -->
                            <v-expansion-panel-header><b>Возврат электронных билетов</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>
                                    Возврат электронных билетов, приобретенных на сайте катка, возможен не позднее чем за 30
                                    минут до начала сеанса.
                                </p>

                                <p>
                                    <b>ПОРЯДОК ВОЗВРАТА </b>
                                </p>
                                <p>
                                    - отправьте заявку на возврат билетов по адресу <a href="mailto: info.katok@gum.ru">info.katok@gum.ru</a> <br />
                                    - во вложении к письму прикрепите билеты из заказа, которые хотите вернуть (катание, прокат) <br />
                                </p>

                                <p>
                                    <b style="color:red">Обратите внимание</b>: электронная почта заявителя должна совпадать с почтой, указанной при
                                    оформлении заказа
                                </p>

                                <p>
                                    Заявка будет рассмотрена и обработана. В случае любого решения, заявитель получит
                                    уведомление на электронную почту.
                                </p>
                                <p>
                                    Денежные средства поступают на счет заявителя в течении нескольких (до 10) рабочих дней на
                                    банковскую карту, с которой оплачивался заказ.
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-btn>
</template>

<script lang="ts">
    import Component from 'vue-class-component';
    import Vue from 'vue';
    @Component
    export default class ButtonFAQ extends Vue {
        modelDialog = false;

        ScrollTo(selector: string) {
            let el = document.querySelector(selector);
            if (el) el.scrollIntoView();
            this.modelDialog = false;
        }
    }
</script>
