<template>
	<div class="section-map"> 
		<v-img :src="ImageMap" />
		<!-- <p class="text-h3 primary--text my-5 text-center">Легенда карты</p> -->
		<div class="px-3 pt-6 px-sm-5 d-flex justify-space-between legend primary--text font-weight-bold">
			<div>
				<div>1. Булочка с корицей</div>
				<div>2. Осетинские пироги</div>
				<div>3. У Аннушки</div>
				<div>4. Ванильный крутиш</div>
			</div>

			<div>
				<div>5. Пончики </div>
				<div>6. Дары моря</div>
				<div>7. Бурка в булке</div>
				<div>8. Беляши и чебуреки</div>
			</div>

			<div>
				<div>9. Русские пряники</div>
				<div>10. Булочка с корицей</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
	name: 'SectionMap',
	data: () => ({
		ImageMap: require('@/assets/images/2024/map.png'),
	}),
});
</script>

<style lang="less" scoped>
.section-map {
	.legend {
		gap: 8px;

		& > div {
			flex: 1;
		}
	}
}

@media (max-width: 600px) {
	.section-map {
		div {
			font-size: 12px;
		}
	}
}
</style>
